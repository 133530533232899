import React from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';



function Layout({ isLoggedIn, setIsLoggedIn }) {
  return (
    <>
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Outlet />
       {/* <Footer /> */}

    </>
  );
}

export default Layout;
