import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(''); // Hata durumunu takip etmek için yeni bir state

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, { email });
      setMessage(response.data.msg);  // Başarılı mesaj
      setError('');  // Hata durumunu temizle
    } catch (err) {
      setError('Bir hata oluştu. Lütfen tekrar deneyin.');  // Hata mesajı
      setMessage('');  // Başarılı mesajı temizle
    }
  };

  return (
    <Container fluid className="d-flex align-items-center justify-content-center min-vh-100">
      <Row className="w-100">
        <Col md={6} lg={4} className="mx-auto p-4">
          <h2 className="text-center mb-4 text-warning">Şifremi Unuttum</h2>
          <Form onSubmit={handleForgotPassword}>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label className='text-warning'>E-posta Adresi</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="E-posta adresinizi girin" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mb-3">
              Şifremi Sıfırla
            </Button>
            {message && <p className="text-center text-success">{message}</p>}  {/* Başarılı mesaj yeşil */}
            {error && <p className="text-center text-danger">{error}</p>}  {/* Hata mesajı kırmızı */}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
