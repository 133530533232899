import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';  // Redux'tan userId'yi almak için
import axios from 'axios';

const Profile = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    tcNo: '',
    birthDate: '',
    email: '',
    phone: '',
    profileImage: '',
    senderName: '',
    companyName: '',
    taxNumber: '',
    taxOffice: ''
  });

  const [updateStatus, setUpdateStatus] = useState(''); // Güncelleme durumu için state
  const [errorMessage, setErrorMessage] = useState(''); // Hata mesajı için state
  const apiUrl = process.env.REACT_APP_API_URL; // API URL'sini kullanıyoruz

  // Redux'tan userId'yi al
  const userId = useSelector((state) => state.user.userId);

  // Kullanıcı verilerini fetch ile çekme fonksiyonu
  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/user/${userId}`, {  // userId kullanılıyor
        method: 'GET',
        credentials: 'include', // Çerezlerle istek yapmak için gerekli
      });

      if (!response.ok) {
        if (response.status === 401) {
          setErrorMessage('Oturumunuz sona erdi, lütfen yeniden giriş yapın.');
        } else {
          setErrorMessage('Kullanıcı verileri alınırken bir hata oluştu.');
        }
        return;
      }

      const data = await response.json();
      setUser(data.user);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setErrorMessage('Sunucuya erişilemedi, lütfen internet bağlantınızı kontrol edin.');
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();  // Kullanıcı verilerini çek
    }
  }, [userId]);

  // E-posta doğrulama isteği
// E-posta doğrulama isteği
const handleEmailVerification = async () => {
  try {
    console.log(`Doğrulama isteği gönderiliyor, userId: ${userId}`);
    
    const response = await fetch(`${apiUrl}/auth/send-verification-email`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),  // userId'yi body'ye ekliyoruz
    });

    if (!response.ok) {
      setUpdateStatus('Doğrulama e-postası gönderilirken bir hata oluştu.');
      return;
    }

    setUpdateStatus('Doğrulama e-postası başarıyla gönderildi. Lütfen e-postanızı kontrol edin.');
  } catch (error) {
    console.error('Doğrulama e-postası gönderilirken hata oluştu:', error);
    setUpdateStatus('Doğrulama e-postası gönderilirken bir hata oluştu.');
  }
};



  // Kullanıcı profilini güncelleme işlemi
  // Profil güncelleme işlemi
// Profil güncelleme işlemi
const handleProfileUpdate = async () => {
  try {
    const response = await fetch(`${apiUrl}/user/${userId}/update`, {  
      method: 'PUT',
      credentials: 'include', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        birthDate: user.birthDate,
        address: user.address,
        companyName: user.companyName,
        taxNumber: user.taxNumber,
        taxOffice: user.taxOffice,
      }),
    });

    if (!response.ok) {
      setUpdateStatus('Profil güncellenirken bir hata oluştu.');
      return;
    }

    setUpdateStatus('Profil başarıyla güncellendi!');
    fetchUserData();  // Güncellenmiş verileri tekrar çekmek için
  } catch (error) {
    console.error('Profil güncellenirken hata oluştu:', error);
    setUpdateStatus('Profil güncellenirken bir hata oluştu.');
  }
};

  // Hesap silme işlemi
  const handleAccountDeletion = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete('http://localhost:5000/api/user/delete', {
        headers: { 'x-auth-token': token }
      });
      localStorage.removeItem('token'); // Token'ı localStorage'dan sil
      window.location.href = '/'; // Kullanıcıyı ana sayfaya yönlendir
    } catch (error) {
      console.error('Hesap silinirken bir hata oluştu:', error);
      setUpdateStatus('Hesap silinirken bir hata oluştu.');
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={8} className="mx-auto">
          <div className="text-center p-4" style={{ backgroundColor: 'white', borderRadius: '30px', color: 'gray' }}>
            {updateStatus && (
              <Alert variant="info" onClose={() => setUpdateStatus('')} dismissible>
                {updateStatus}
              </Alert>
            )}

            {errorMessage && (
              <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
                {errorMessage}
              </Alert>
            )}

            {/* Kullanıcı Profil Resmi - İsim ve Soyisim Baş Harfleri */}
            <div className="profile-avatar mb-4" style={{ textAlign: 'center' }}>
              <div
                style={{
                  cursor: 'default',
                  display: 'inline-block',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  width: '120px',
                  height: '120px',
                  backgroundColor: '#FFD700',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: '120px',
                    height: '120px',
                    fontSize: '36px',
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  {`${user.firstName[0]}${user.lastName[0]}`}
                </div>
              </div>
              <h4 className="mt-3">{`${user.firstName} ${user.lastName}`}</h4>
              <p>{user.email}</p>
              <p>{user.phone}</p>
            </div>

            {/* Kimlik Bilgileri */}
            <h5 className="mt-4">KİMLİK BİLGİLERİ</h5>
            <hr />
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="firstName">
                    <Form.Label>İsim</Form.Label>
                    <Form.Control
                      type="text"
                      value={user.firstName}
                      onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="lastName">
                    <Form.Label>Soyisim</Form.Label>
                    <Form.Control
                      type="text"
                      value={user.lastName}
                      onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="tcNo">
                    <Form.Label>TC No</Form.Label>
                    <Form.Control type="text" value={user.tcNo} readOnly />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="birthDate">
                    <Form.Label>Doğum Tarihi</Form.Label>
                    <Form.Control type="text" value={new Date(user.birthDate).toLocaleDateString()} readOnly />
                  </Form.Group>
                </Col>
              </Row>
{/* 
              <Button variant="primary" className="mt-4" onClick={handleProfileUpdate}>
                Profili Güncelle
              </Button> */}
            </Form>

            <h5 className="mt-4">İLETİŞİM BİLGİLERİ</h5>
            <hr />
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Telefon Numarası</Form.Label>
                    <Form.Control type="text" value={user.phone} readOnly />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>E-Posta Adresi</Form.Label>
                    <Form.Control type="text" value={user.email} readOnly />
                  </Form.Group>
                  <Button variant="danger" className="w-100 mt-2" onClick={handleEmailVerification}>
                    E-Posta Adresimi Doğrula
                  </Button>
                </Col>
              </Row>
            </Form>

            <Link to="/panelim/kaldır" className="btn btn-danger mt-4" onClick={handleAccountDeletion}>
              Hesabımı Kapat
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
