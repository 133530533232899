import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // useNavigate eklendi
import { Container, Navbar, Nav, ListGroup, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import axios from 'axios';
import "../assets/css/swap.css";
import "../assets/css/fancybox.min.css";
import "../assets/css/magnific-popup.min.css";
import "../assets/css/aos.css";
import "../assets/css/slick.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/media_query.css";
import logo from '../assets/images/Kargo/indirimli.png';
import { FaWhatsapp, FaSquarePhone } from "react-icons/fa6";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { HashLink as Link } from 'react-router-hash-link';

function Header({ isLoggedIn, setIsLoggedIn }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("up");
  const location = useLocation();
  const navigate = useNavigate(); // useNavigate hook'u eklendi
const apiUrl = process.env.REACT_APP_API_URL;
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

const handleLogout = async () => {
  try {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken'); // Refresh token'ı alıyoruz

    // Logout isteği yapıyoruz ve hem access token hem de refresh token'ı gönderiyoruz
    await axios.post(`${apiUrl}/auth/logout`, { refreshToken }, {
      headers: { 'x-auth-token': token }
    });

    // Token'ları localStorage'dan siliyoruz
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');

    setIsLoggedIn(false);
    closeMenu();
    navigate('/'); // Kullanıcıyı ana sayfaya yönlendir
  } catch (error) {
    console.error('Çıkış yapılırken bir hata oluştu:', error);
  }
};

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
        if (window.scrollY > lastScrollY) {
          setScrollDirection("down");
        } else {
          setScrollDirection("up");
        }
      } else {
        setIsScrolled(false);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <motion.header
      id="top-navbar"
      className={`top-navbar ${isScrolled ? 'scrolled' : ''}`}
      initial={{ y: 0 }}
      animate={{ y: scrollDirection === "down" ? "-100%" : "0" }}
      transition={{ duration: 0.1 }}
    >
      <Container>
        <Navbar expand="lg" expanded={isMenuOpen} className="d-lg-none">
          <Navbar.Brand as={Link} to="/" smooth>
            <div className="brand-title-slogan-wrapper">
              <div className='brand'>
                <img src={logo} alt="logo" className="header-logo" />
              </div>
              <div className='brand-title'>
                <h1 className="header-logo-title">İNDİRİMLİ KARGO</h1>
              </div>
            </div>
            <div className="brand-slogan">
              <p className="header-logo-slogan">TEK TIKLA KARGOLA ...</p>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Item>
                <Nav.Link as={Link} to="/nasil-calisir" onClick={closeMenu}>Nasıl Çalışır</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/fiyatlar" onClick={closeMenu}>Fiyatlar</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/hizmetlerimiz" onClick={closeMenu}>Hizmetlerimiz</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/iletişim" onClick={closeMenu}>İletişim</Nav.Link>
              </Nav.Item>
              {isLoggedIn ? (
                <>
                  <Nav.Item>
                    <Nav.Link as={Link} to="/hoşgeldiniz" onClick={closeMenu}>Kargom</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={Link} to="/panelim" onClick={closeMenu}>Panelim</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={handleLogout}>Çıkış Yap</Nav.Link>
                  </Nav.Item>
                </>
              ) : (
                <>
                  <Nav.Item>
                    <Nav.Link as={Link} to="/giriş-yap" onClick={closeMenu}>Giriş Yap</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={Link} to="/üye-ol" onClick={closeMenu}>Üye Ol</Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div className="d-none d-lg-block">
          <div className="top-navbar_full">
            <div className="back-btn">
              <Link to="/">
                <div className="brand-logo-text">
                  <div className="brand-title-slogan-wrapper">
                    <div className='brand'>
                      <img src={logo} alt="logo" className="header-logo" />
                    </div>
                    <div className='brand-title'>
                      <h1 className="header-logo-title">İNDİRİMLİ KARGO</h1>
                    </div>
                  </div>
                  <div className="brand-slogan">
                    <p className="header-logo-slogan">TEK TIKLA KARGOLA ...</p>
                  </div>
                </div>
              </Link>
            </div>
            
            <div className="hell">
              <Nav className="top-navbar-title">
                <Nav.Item>
                  <Nav.Link as={Link} to="/nasil-calisir">Nasıl Çalışır</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} smooth to="/fiyatlar">Fiyatlar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} smooth to="/hizmetlerimiz">Hizmetlerimiz</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} smooth to="/iletişim">İletişim</Nav.Link>
                </Nav.Item>
                {isLoggedIn ? (
                  <>
                    <Nav.Item>
                      <Nav.Link as={Link} to="/hoşgeldiniz">Kargom</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to="/panelim">Panelim</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={handleLogout}>Çıkış Yap</Nav.Link>
                    </Nav.Item>
                  </>
                ) : (
                  <>
                    <Nav.Item>
                      <a href="/giriş-yap" className={`nav-link ${location.pathname === '/giriş-yap' ? 'menu-active' : ''}`}>
                        Giriş Yap
                      </a>
                    </Nav.Item>
                    <Nav.Item>
                      <a href="/üye-ol" className={`nav-link signup-button ${location.pathname === '/üye-ol' ? 'menu-active' : ''}`}>
                        Üye Ol
                      </a>
                    </Nav.Item>
                  </>
                )}
              </Nav>
            </div>
          </div>

          <div className="outer-menu">
            <input
              className="checkbox-toggle"
              type="checkbox"
              checked={isMenuOpen}
              onChange={toggleMenu}
            />
            <div className="hamburger" onClick={toggleMenu}>
              <div></div>
            </div>
            {isMenuOpen && (
              <div className="menu">
                <div className="container">
                  <div className="row sidemenu-main">
                    <div className="col-lg-7 side-menu-scroll">
                      <div className="heading2"><Link to="/nasil-calisir" onClick={closeMenu}>Nasıl Çalışır</Link></div>
                      <div className="heading2"><Link to="/fiyatlar" onClick={closeMenu}>Fiyatlar</Link></div>
                      <div className="heading2"><Link to="/hizmetlerimiz" onClick={closeMenu}>Hizmetlerimiz</Link></div>
                      <div className="heading2"><Link to="/iletişim" onClick={closeMenu}>İletişim</Link></div>
                      {isLoggedIn ? (
                        <>
                          <div className="heading2"><Link to="/kargom" onClick={closeMenu}>Kargom</Link></div>
                          <div className="heading2"><Link to="/panelim" onClick={closeMenu}>Panelim</Link></div>
                          <div className="heading2"><Link onClick={handleLogout}>Çıkış Yap</Link></div>
                        </>
                      ) : (
                        <>
                          <div className="heading2"><Link to="/üye-ol" onClick={closeMenu}>Ücretsiz Kayıt Ol</Link></div>
                          <div className="heading2"><Link to="/giriş-yap" onClick={closeMenu}>Giriş Yap</Link></div>
                        </>
                      )}
                    </div>
                    <div className="col-lg-5">
                      <h3>Yardıma mı İhtiyacınız Var?</h3>
                      <ListGroup variant="flush" className="contact-list">
                        <ListGroup.Item className="contact-item">
                          <Row>
                            <Col xs={2} className="icon">
                              <FaSquarePhone size={30} />
                            </Col>
                            <Col xs={10}>
                              <h5>Bizi Arayabilirsiniz</h5>
                              <p>850 304 0555</p>
                              <p>09:00 / 19:00</p>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item className="contact-item">
                          <Row>
                            <Col xs={2}>
                              <FaWhatsapp size={30} style={{ color: '#25D366' }} />
                            </Col>
                            <Col xs={10}>
                              <h5>Mesaj Yazabilirsiniz</h5>
                              <p>850 304 0555</p>
                              <p>09:00 / 24:00</p>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col xs={2} className="icon">
                              <MdOutlineMarkEmailRead size={30} />
                            </Col>
                            <Col xs={10}>
                              <h5>E-Posta Gönderebilirsiniz</h5>
                              <p>destek@indirimlikargo.com</p>
                              <p>7/24</p>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </motion.header>
  );
}

export default Header;
