// Initial State
const initialState = {
  userId: null,  // Başlangıçta null
  storeId: null,  // Başlangıçta storeId de null olacak
};

// User Reducer
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        userId: action.payload.userId,  // Redux store'a userId kaydedilir
        storeId: action.payload.storeId,  // Redux store'a storeId kaydedilir
      };
    default:
      return state;
  }
};
