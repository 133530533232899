import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

const KargoInfo = () => {
  return (
    <Container fluid className="p-3" style={{ backgroundColor: '#1c1c1c', color: 'white', borderRadius: '10px', maxWidth: '100%', marginTop: '120px', overflow: 'auto' }}>
      <Row className="mb-3">
        <Col xs={1}>
          <FaInfoCircle size={20} style={{ color: 'white' }} />
        </Col>
        <Col>
          <h5>NASIL KARGO GÖNDERİRİM?</h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2} sm={1} className="d-flex align-items-center justify-content-center">
          <div style={{
            width: '12px',
            height: '12px',
            backgroundColor: 'white',
            borderRadius: '50%',
            border: '3px solid #FFD700'
          }}></div>
        </Col>
        <Col xs={10} sm={11}>
          <h6>CÜZDANA BAKİYE YÜKLE</h6>
          <p>Kargo Ücretleri Dijital Cüzdan Hesabınızdan Tahsil Edileceğinden, Öncelikle Cüzdanınızda, Her Bir Kargo Gönderimi İçin, xx,xx ₺ Kadar Bakiyeniz Olmalıdır. Dilerseniz Buradan Cüzdan Hesabınıza Kolayca Bakiye Yükleyebilirsiniz.</p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={2} sm={1} className="d-flex align-items-center justify-content-center">
          <div style={{
            width: '12px',
            height: '12px',
            backgroundColor: 'white',
            borderRadius: '50%',
            border: '3px solid #FFD700'
          }}></div>
        </Col>
        <Col xs={10} sm={11}>
          <h6>GÖNDERİ BİLGİLERİ GİR</h6>
          <p>Alt Orta Kısımda Yer Alan <strong>+ Oluştur → Yurtiçi Kargo Oluştur</strong> Butonlarını ile Açılan Sayfaları Takip Ederek, Gönderi Bilgilerini Saniyeler İçerisinde Sisteme Kolayca Gir.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={2} sm={1} className="d-flex align-items-center justify-content-center">
          <div style={{
            width: '12px',
            height: '12px',
            backgroundColor: 'white',
            borderRadius: '50%',
            border: '3px solid #FFD700'
          }}></div>
        </Col>
        <Col xs={10} sm={11}>
          <h6>KARGOLARINI GÖNDER</h6>
          <p>Seçtiğin Kargo Firmasının Dilediğin Şubesine, Sadece Sistemin Tarafınıza Anlık Olarak İleteceği, Kargo Referans Kodunu Belirterek veya Barkod Çıktısıyla, Ödeme ve Başka Hiç Bir Prosedüre Uğramadan Kargolarını Teslim Edebilirsin.</p>
          <p style={{ color: '#FFD700' }}>Detaylı Kullanım Videosu için Tıklayınız</p>
        </Col>
      </Row>
    </Container>
  );
};

export default KargoInfo;
