import React, {useEffect} from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import "../assets/css/swap.css";
import "../assets/css/fancybox.min.css";
import "../assets/css/magnific-popup.min.css";
import "../assets/css/aos.css";
import "../assets/css/slick.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/media_query.css";
import '../assets/css/Home.css';
import '../assets/css/Animate.css';

import mediaLineTop from '../assets/images/media-line-top.svg';
import medaiLineBottom from '../assets/images/media-line-bottom.svg';
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { IoMailUnreadOutline } from "react-icons/io5";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import handImage from '../assets/images/Kargo/handlast.png';

import PTT from '../assets/images/Kargo/pttbg.webp';
import UPS from '../assets/images/Kargo/upsbg.webp';
import Yurt from '../assets/images/Kargo/yurtiçikargobg.webp';
import How from './How';
import CargoPricing from './CargoPricing';
import Services from './Services';
import '../Components/Animation/framer-motion'
import Footer from './Footer';

const Home = ({ scrollTarget }) => {

  const location = useLocation(); // Mevcut sayfanın URL'sini izlemek için kullanılır

  useEffect(() => {
    if (scrollTarget) {
      const element = document.getElementById(scrollTarget);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [scrollTarget]);

  const history = useNavigate();

  const handleReadMoreClick = () => {
    history.push('/alt-sayfa'); // Buraya yönlendirmek istediğiniz sayfanın yolunu ekleyin
  };

  const triggerPageLoader = () => {
    const pageLoader = document.querySelector('.page-loader');
    if (pageLoader) {
      pageLoader.style.display = 'block';
      setTimeout(() => {
        pageLoader.style.display = 'none';
      }, 1000);
    }
  };

  useEffect(() => {
    triggerPageLoader(); // Sayfa yüklendiğinde veya URL değiştiğinde animasyonu çalıştır

    // jQuery'yi global tanımla
    window.$ = window.jQuery = $;

    // Fancybox
    import('@fancyapps/fancybox').then(() => {
      if ($ && $.fn.fancybox) {
        $('[data-fancybox]').fancybox();
      } else {
        console.error("Fancybox yüklenemedi.");
      }
    }).catch(err => {
      console.error("Fancybox yüklenemedi:", err);
    });

    // Magnific Popup
    import('magnific-popup').then(() => {
      if ($ && $.fn.magnificPopup) {
        $('.popup-link').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true
          }
        });
      } else {
        console.error("Magnific Popup yüklenemedi.");
      }
    }).catch(err => {
      console.error("Magnific Popup yüklenemedi:", err);
    });
  }, [location.pathname]);

  return (
    <Container fluid className='md home-container mt-7' id="home-section" style={{ marginTop: '100px' }}>
      <div className="site_contain" >
        {/* Sayfa yükleniyor animasyonu */}
        <div className="page-loader">
          <div className="loader"></div>
          <div className="shadow"></div>
        </div>
        <Row>
          {/* Orta Kısım - %65 - Mobilde en üstte olacak */}
          <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }} lg={7}>
            <Row>
              <Col className="container2 animate-top" style={{ height: '45vh' }}>
                <div className='main-text'>
                  <h5>Taahhütsüz & Sınırsız </h5>
                  <h5 style={{ fontSize: '50px' }}>
                    <span style={{ color: '#CB6CE6' }}>%60'a</span>&nbsp;
                    Varan&nbsp;
                    <span style={{ color: '#CB6CE6' }}>İndirimle</span>
                  </h5>
                </div>
                {/* Logoları buraya ekledik */}
                <Row className="kargo-logos d-flex justify-content-between align-items-center mt-2">
                  <Col xs={6} md={3} sm={3} className="d-flex justify-content-center mb-2">
                    <Image src={PTT} alt="Logo 1" className="ptt" style={{ width: '300px', maxWidth: '300px' }} />
                  </Col>
                  <Col xs={6} md={3} className="d-flex justify-content-center mb-2">
                    <Image src={UPS} alt="Logo 2" className="ups" style={{ width: '100%', maxWidth: '200px' }} />
                  </Col>
                  <Col xs={6} md={3} className="d-flex justify-content-center mb-2">
                    <Image src={Yurt} alt="Logo 3" className="yurt" style={{ width: '100%', maxWidth: '200px' }} />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-center">
              {/* Orta Alt Kısım - %75 */}
              <Col className="d-flex justify-content-center">
                {/* Sol taraf - resim */}
                <Col xs={9} className="main-img1 d-flex justify-content-center align-items-center">
                  <Image src={handImage} alt="Ana Resim" fluid />
                </Col>
              </Col>
            </Row>
          </Col>

          {/* Sağ Kısım - %20 - Mobilde ikinci sırada */}
          <Col xs={{ span: 10, order: 2 }} md={{ span: 2, order: 3 }} lg={3} className="right-box p-1 animate-left" style={{ borderLeft: '1px solid gray' }}>
            {/* İlk Box */}
            <div className="mb-4 p-3 text-light" style={{ borderRight: '2px solid gray', borderBottom: '2px solid gray' }}>
              <h5>TEK TUŞLA</h5>
              <p>Seçtiğin firma ile anında indirimli Kargo Gönder</p>
              <p>Takip Et</p>
              <p>E- Fatura Gönder / Al</p>
              <p>Link İle Ödeme Al</p>
              <p>Kapıda Ödemeli Satış Yap</p>
            </div>

            {/* İkinci Box */}
            <div className="p-3 text-light" style={{ borderRight: '2px solid gray', borderBottom: '2px solid gray' }}>
              <h5>BASİT!</h5>
              <p className="text-gray">Mobil Uygulama desteğiyle gönderi durumları anlık tarafını bildirilsin!</p>
              <p>API desteği ile pazaryeri ve e-ticaret mağazanı otomatik olarak yönet!</p>
            </div>

            {/* Daha Fazla ve Aşağı Yönlü Ok */}
            <div className="text-center mt-4">
              <Button variant="link" onClick={handleReadMoreClick} className="text-light">
                Daha Fazla
              </Button>
              <div className="arrow-down" onClick={handleReadMoreClick} style={{ cursor: 'pointer', color: 'white' }}>
                <FaRegArrowAltCircleDown size={50} />
              </div>
            </div>
          </Col>

          {/* Sol Kısım - %15 - Mobilde en altta */}
          <Col xs={{ span: 12, order: 3 }} md={{ span: 2, order: 1 }} lg={2} className="left-box animate-right">
            <div className="section-main overflow-hidden" >
              <div className="section-main-sub position-relative">
                <div className="media-main2">
                  <Image src={mediaLineTop} alt="media-line-top" />
                  <div>
                    <a href="mailto:indirimlikargotr@gmail.com" target="_blank" rel="noopener noreferrer">
                      <IoMailUnreadOutline />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.facebook.com/profile.php?id=61561011802011" target="_blank" rel="noopener noreferrer">
                      <FaFacebookF />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.instagram.com/indirimlikargo/?utm_medium=copy_link" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </div>
                  <div>
                    <a href="https://x.com/sanatkardan?lang=en" target="_blank" rel="noopener noreferrer">
                      <FaTwitter />
                    </a>
                  </div>
                  <Image src={medaiLineBottom} alt="media-line-bottom" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <How />
      <CargoPricing />
      <Services />
      <Footer />
    </Container>
  );
};

export default Home;
