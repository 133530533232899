import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import logo from '../../assets/images/Kargo/indirimli.png';
import '../../assets/css/Login.css';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL; // API URL'sini alıyoruz
  const dispatch = useDispatch(); // Redux dispatch kullanıyoruz
  const navigate = useNavigate(); // navigate ile yönlendirme

  const handleLogin = async (e) => {
    e.preventDefault();

    // Log: Login işlemi başladı
    console.log('Login işlemi başlatıldı. Email:', email, 'Password:', password);

    try {
      // Backend'e POST isteği atılıyor
      console.log('API isteği başlatılıyor: ', `${apiUrl}/auth/login`);
      
      const response = await axios.post(`${apiUrl}/auth/login`, 
        { email, password }, 
        { withCredentials: true }  // Cookie'leri almak için gerekli
      );

      // Log: API'den dönen yanıt
      console.log('API yanıtı alındı:', response);

      if (response.status === 200) {
        console.log('Başarılı giriş:', response.data);

        // Redux store'a kullanıcı bilgilerini kaydet
        console.log('Redux\'a userId ve storeId dispatch ediliyor...');
        dispatch({
          type: 'SET_USER',
          payload: { userId: response.data.userId, storeId: response.data.storeId },
        });

        // Log: Redux'a kaydedilen userId ve storeId
        console.log('Dispatch edilen userId:', response.data.userId, 'storeId:', response.data.storeId);

        // Oturum durumu güncelleniyor
        setIsLoggedIn(true);
        console.log('Giriş başarılı, oturum güncellendi.');

        // Kullanıcıyı yönlendiriyoruz
        navigate('/hoşgeldiniz');
        console.log('Kullanıcı hoşgeldiniz sayfasına yönlendirildi.');
      }
    } catch (err) {
      // Log: Hata durumunda dönen yanıt
      console.error('Login hatası:', err);

      // Hata mesajını backend'den al veya genel bir mesaj göster
      setError(err.response?.data.msg || 'Geçersiz e-posta veya şifre.');
      console.log('Gösterilen hata mesajı:', err.response?.data.msg || 'Geçersiz e-posta veya şifre.');
    }
  };

  return (
    <Container fluid className="login-container d-flex align-items-center justify-content-center min-vh-100">
      <Row className="w-100">
        <h2 className="text-center mb-4 login-title">Giriş Yap</h2>
        <Col md={4} sm={8} xs={10} className="mx-auto p-4 login-box" style={{ maxWidth: '400px' }}>
          <div className="text-center mb-4">
            <div className="d-flex align-items-center justify-content-center">
              <Image src={logo} alt="İndirimli Kargo Logo" style={{ width: '40px' }} />
              <h4 className="mb-0 ms-2 text-warning">İNDİRİMLİ KARGO</h4>
            </div>
          </div>

          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Control
                type="email"
                placeholder="E - Posta Adresinizi Giriniz"
                className="login-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Control
                type="password"
                placeholder="Parolanızı Giriniz"
                className="login-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            {error && <p className="text-danger">{error}</p>}

            <Button type="submit" variant="warning" className="w-100 mb-3 login-button">
              GİRİŞ YAP
            </Button>

            <p className="mt-4">Giriş Yap!</p>

            <p>
              Halen Kayıt Olmadın mı? <a href="/üye-ol" className="text-warning">Hemen Kayıt Ol!</a>
            </p>
            <p>
              <a href="/sifremi-unuttum" className="text-warning">Şifremi Unuttum!</a>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
