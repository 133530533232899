import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import allLogos from '../../../assets/images/Kargo/api.png'; // Ortadaki logoların hepsini içeren resim

const SalesInfo = () => {
  return (
    <Container 
      fluid 
      className="d-flex align-items-center justify-content-center min-vh-100" 
      style={{ backgroundColor: '#1c1c1c', color: 'white', borderRadius: '10px', maxWidth: '100%', marginTop: '20px', textAlign: 'center' }}
    >
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={11} sm={10} md={8} lg={6} xl={4} className="p-4" style={{ backgroundColor: '#333', borderRadius: '10px' }}>
          <div className="text-center mb-3">
            <FaInfoCircle size={24} style={{ color: 'white' }} />
            <h6 className="mt-3" style={{ fontSize: '14px' }}>ONLINE PAZARYERLERİ VEYA KENDİ E-TİCARET SİTENDEN SATIŞ YAPIYORSAN!</h6>
          </div>
          <div className="text-center mb-4">
            <div style={{ display: 'inline-block', maxWidth: '300px', margin: '0 auto' }}>
              <Image src={allLogos} fluid style={{ width: '100%', height: 'auto' }} />
            </div>
          </div>
          <div className="text-center mb-3">
            <p style={{ fontWeight: 'bold', fontSize: '14px' }}>TAMAMEN ÜCRETSİZ</p>
            <p style={{ fontSize: '12px' }}>API - ENTEGRASYON HİZMETİMİZLE SATIŞ KANALLARINI BAĞLAYABİLİR</p>
            <p style={{ fontSize: '12px' }}>KARGO VE E-FATURALARINI TEK TUŞLA KOLAYCA OLUŞTURUP TEK PANELDE ORGANİZE EDEBİLİRSİN</p>
          </div>
          <div className="text-center mt-4">
            <Button variant="warning" size="sm" style={{ fontSize: '14px' }}>BAŞVUR</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SalesInfo;
