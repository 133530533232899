import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Tooltip, OverlayTrigger, Table, Offcanvas, Accordion, Form } from 'react-bootstrap';
import { BsWallet, BsInfoCircle, BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Tarih seçici için gerekli CSS
import BalanceLoad from '../Panel/Wallet/BalanceLoad';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { IoWalletOutline } from "react-icons/io5";

import '../assets/css/wallet.css';

const Wallet = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [balance, setBalance] = useState(0);
  const [blockedBalance, setBlockedBalance] = useState(0);
  const [usableBalance, setUsableBalance] = useState(0);
  const [averageShippingCost, setAverageShippingCost] = useState(0);
  const [transactions, setTransactions] = useState([]);

  // Yeni eklenen state'ler
  const [transactionType, setTransactionType] = useState('');
  const [expenseType, setExpenseType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Redux'tan userId'yi alıyoruz
  const userId = useSelector((state) => state.user.userId);
  const apiUrl = process.env.REACT_APP_API_URL; // Dinamik API URL

  // Cüzdan verilerini alma
  const fetchWalletData = async () => {
    try {
      const balanceResponse = await axios.get(`${apiUrl}/wallet/balance`, {
        withCredentials: true,
      });

      // Yanıtı konsola yazdır
      console.log("Balance Response:", balanceResponse.data);

      // State'leri güncelle
      setBalance(balanceResponse.data.balance || 0);
      setBlockedBalance(balanceResponse.data.blockedBalance || 0);
      setUsableBalance(balanceResponse.data.usableBalance || 0); // Kullanılabilir bakiye
      setAverageShippingCost(balanceResponse.data.averageShippingCost || 0);

      // Eğer işlem geçmişini ayrı bir endpoint ile almak istiyorsanız burayı ekleyin
      const transactionsResponse = await axios.get(`${apiUrl}/wallet/transactions`, {
        withCredentials: true,
      });

      // İşlem geçmişi yanıtını konsola yazdır
      console.log("Transactions Response:", transactionsResponse.data);
      setTransactions(transactionsResponse.data.transactions || []);
    } catch (error) {
      console.error('Wallet data fetching error:', error);
    }
  };

  // İlk yükleme ve userId değişikliği için veri çekme
  useEffect(() => {
    fetchWalletData();
  }, [userId]);

  // Daha fazla işlem yükleme
  const loadMoreTransactions = async (page) => {
    try {
      const transactionsResponse = await axios.get(`${apiUrl}/wallet/${userId}/transactions?page=${page}`, {
        withCredentials: true, // Cookies'i dahil etmek için gerekli
      });
      setTransactions((prevTransactions) => [...prevTransactions, ...transactionsResponse.data]);
    } catch (error) {
      console.error('Error loading more transactions:', error);
    }
  };

  // Cüzdan hareketlerini alma
  const fetchTransactions = async () => {
    try {
      const response = await axios.get(`${apiUrl}/wallet/transactions`, {
        withCredentials: true,
      });

      // İşlem geçmişini konsola yazdır
      console.log("Transactions Response:", response.data);
      setTransactions(response.data.transactions || []);
    } catch (error) {
      console.error('Transactions fetching error:', error);
      alert('İşlemleri yüklerken bir hata oluştu.');
    }
  };

  // İlk yükleme ve userId değişikliği için veri çekme
  useEffect(() => {
    fetchWalletData();
    fetchTransactions(); // Cüzdan hareketlerini al
  }, [userId]);

  // Filtreleme işlemi için
  const handleFilterSearch = async () => {
    try {
      const response = await axios.get(`${apiUrl}/wallet/transactions`, {
        params: {
          transactionType,
          expenseType,
          startDate: startDate ? startDate.toISOString() : undefined,
          endDate: endDate ? endDate.toISOString() : undefined,
          searchQuery,
        },
        withCredentials: true, // Cookies'i dahil etmek için gerekli
      });

      // Filtrelenmiş işlemleri konsola yazdır
      console.log('Filtered Transactions Response:', response.data);
      setTransactions(response.data.transactions || []);
    } catch (error) {
      console.error('Filter search error:', error);
    }
  };

  // Tooltip içeriği
  const tooltip = (
    <Tooltip id="tooltip-basic" style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', padding: '10px' }}>
      <p style={{ margin: '0', color: '#FFFF00' }}><strong>GÖNDERİ ORTALAMASI:</strong></p>
      <p style={{ margin: '0' }}>Son gönderdiğiniz 10 adet kargo taşıma bedelinin ortalaması</p>
      <p style={{ margin: '0', color: '#FFFF00' }}><strong>BLOKLANAN BAKİYE:</strong></p>
      <p style={{ margin: '0' }}>Henüz teslim etmediğiniz kargoların bloklanmış tutarı</p>
      <p style={{ margin: '0', color: '#FFFF00' }}><strong>KULLANILABİLİR BAKİYE:</strong></p>
      <p style={{ margin: '0' }}>Bloklanan tutardan sonra kullanılabilir bakiye</p>
      <p style={{ margin: '0', color: '#FFFF00' }}><strong>BAKİYE:</strong></p>
      <p style={{ margin: '0' }}>Kullanılabilir ve bloklanan toplam bakiye</p>
    </Tooltip>
  );

  return (
    <Container fluid className="py-4">
      <Row className="align-items-center mb-3">
        <Col xs={12} md={6}>
          <h4 className="mb-0">CÜZDAN</h4>
        </Col>
      </Row>

      <Row className="align-items-stretch mb-4">
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill p-3">
            <Row>
              <Col xs={3} className="d-flex align-items-center justify-content-center">
                <IoWalletOutline  size={50} />
              </Col>
              <Col xs={9}>
                <h3>{balance.toFixed(2)}₺</h3>
                <Button
                  variant="link"
                  className="p-0"
                  style={{ textDecoration: 'none', color: '#EFB02F' }} // Yazı rengi sarı
                  onClick={() => setShowOffcanvas(true)}
                >
                  BAKİYE YÜKLE / ÇEK
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill p-3">
            <p className="mb-1">
              Gönderi ortalaması: <strong>{averageShippingCost.toFixed(2)}₺</strong> Bakiye: <strong>{balance.toFixed(2)}₺</strong>
            </p>
            <p className="mb-1">
              Bloklanan Bakiye: <strong>{blockedBalance.toFixed(2)}₺</strong>&nbsp;
              Kullanılabilir Bakiye: <strong>{usableBalance.toFixed(2)}₺</strong>
            </p>

            <Button
              variant="link"
              className="p-0"
              style={{ textDecoration: 'none', color: '#EFB02F' }} // Yazı rengi sarı
              onClick={() => loadMoreTransactions(1)}
            >
              TÜM HAREKETLER
            </Button>

            <OverlayTrigger 
              placement="left" 
              overlay={tooltip}
            >
              <Button variant="link" className="p-0" style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <BsInfoCircle size={20} />
              </Button>
            </OverlayTrigger>
          </Card>
        </Col>
      </Row>

      {/* Arama ve Filtreleme Accordion */}
      <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Arama, Filtreleme</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col xs={12} md={4}>
                <Card className="p-3">
                  <Form.Label><strong>İşlem Türü</strong></Form.Label>
                  <Form.Check type="radio" label="Para Girişi" name="transactionType" onChange={() => setTransactionType('GELİR')} />
                  <Form.Check type="radio" label="Para Çıkışı" name="transactionType" onChange={() => setTransactionType('GİDER')} />
                  <Form.Check type="radio" label="Bloke" name="transactionType" onChange={() => setTransactionType('BLOKE')} />
                  <Button variant="warning" className="mt-3" onClick={handleFilterSearch}>Filtrele</Button>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card className="p-3">
                  <Form.Label><strong>Harcama Türü</strong></Form.Label>
                  <Form.Check type="radio" label="Kargo" name="expenseType" onChange={() => setExpenseType('Kargo')} />
                  <Form.Check type="radio" label="Fatura" name="expenseType" onChange={() => setExpenseType('Fatura')} />
                  <Form.Check type="radio" label="Hizmet" name="expenseType" onChange={() => setExpenseType('Hizmet')} />
                  <Button variant="warning" className="mt-3" onClick={handleFilterSearch}>Filtrele</Button>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card className="p-3">
                  <Form.Label><strong>Tarih Aralığı ile Filtrele</strong></Form.Label>
                  <Row>
                    <Col>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Başlangıç Tarihi"
                        className="form-control"
                      />
                    </Col>
                    <Col>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Bitiş Tarihi"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                  <Button variant="warning" className="mt-3" onClick={handleFilterSearch}>Ara</Button>
                  <Form.Label className="mt-3"><strong>Müşteri Ara</strong></Form.Label>
                  <Form.Control type="text" placeholder="Müşteri İsim / Soy İsim veya Telefon" onChange={(e) => setSearchQuery(e.target.value)} />
                  <Button variant="warning" className="mt-3" onClick={handleFilterSearch}>Ara</Button>
                </Card>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Cüzdan Hareketleri Tablosu */}
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header>
              <h5 className="mb-0">CÜZDAN HAREKETLERİ</h5>
            </Card.Header>
            <Card.Body>
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th><BsArrowUpCircle size={20} /></th>
                    <th>İşlem</th>
                    <th>Tutar</th>
                    <th>Harcama Türü</th>
                    <th>Tarih</th>
                    <th>Harcama Detayı</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.type === 'GELİR' ? <BsArrowUpCircle size={20} style={{ color: 'green' }} /> : <BsArrowDownCircle size={20} style={{ color: 'red' }} />}</td>
                      <td style={{ color: transaction.type === 'GELİR' ? 'green' : 'red' }}>{transaction.type}</td>
                      <td style={{ color: transaction.type === 'GELİR' ? 'green' : 'red' }}>{transaction.amount}₺</td>
                      <td>{transaction.category}</td>
                      <td>{new Date(transaction.date).toLocaleDateString()}</td>
                      <td>{transaction.details}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Offcanvas for Bakiye Yükle / Çek */}
      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end" backdrop={true} scroll={true}>
        <Offcanvas.Header closeButton className='text-warning'>
          <Offcanvas.Title className="w-100 text-center">BAKİYE YÜKLE / ÇEK</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <BalanceLoad 
            usableBalance={usableBalance} 
          />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default Wallet;
