import React, { useState, createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './Layout/Layout';
import Login from './Components/Authentication/Login';
import Register from './Components/Authentication/Register';
import ForgotPassword from './Components/Authentication/ForgotPassword';
import ResetPassword from './Components/Authentication/ResetPassword';
import Welcome from './Components/Authentication/Welcome';
import KargoInfo from './Components/Authentication/kargom/KargoInfo';
import Efatura from './Components/Authentication/kargom/Efatura';
import Api from './Components/Authentication/kargom/Api';
import DashboardRouter from './Panel/DashboardRouter';
import ProtectedRoute from './Panel/ProtectedRoute';
import PaymentFailure from './Panel/Wallet/PaymentFailure.jsx';
import PaymentSuccess from './Panel/Wallet/PaymentSuccess';
import PaymentEftSuccess from './Panel/Wallet/PaymentEftSuccess.jsx';

import Kvkp from './Components/legallink/Kvkp.jsx';
import TermsofUse from './Components/legallink/TermsofUse.jsx';
import SalesAgreement from './Components/legallink/SalesAgreement.jsx';
import CookiePolicy from './Components/legallink/CookiePolicy.jsx';
import TaxExemption from './Components/legallink/TaxExemption.jsx';
import Distance from './Components/legallink/Distance.jsx';



const AuthContext = createContext();

// Context üzerinden oturum bilgisini kullanmak için
export const useAuth = () => useContext(AuthContext);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // Giriş durumu

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}>
            <Route index element={<Home />} />
            <Route path="/nasil-calisir" element={<Home scrollTarget="nasil-calisir" />} />
            <Route path="/fiyatlar" element={<Home scrollTarget="fiyatlar" />} />
            <Route path="/hizmetlerimiz" element={<Home scrollTarget="hizmetlerimiz" />} />
            <Route path="/iletişim" element={<Home scrollTarget="iletişim" />} />
            <Route path="/giriş-yap" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/üye-ol" element={<Register />} />
            <Route path="/hoşgeldiniz" element={<Welcome />} />
            <Route path="/sifremi-unuttum" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/nasıl-kargo-gönderirim" element={<KargoInfo />} />
            <Route path="/nasıl-efatura-kullanırım" element={<Efatura />} />
            <Route path="/nasıl-api-entegrasyonu-yaparım" element={<Api />} />
            <Route path="/payment-failure" element={<PaymentFailure />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-eftsuccess" element={<PaymentEftSuccess />} /> 
          <Route path="/kisisel-verilerin-korunmasi-politikasi" element={<Kvkp />} />
<Route path="/kullanim-kosullarimiz" element={<TermsofUse />} />
<Route path="/aydinlatma-ve-acik-riza-metni" element={<SalesAgreement />} />
<Route path="/gizlilik-ve-cerez-politikasi" element={<CookiePolicy />} />
<Route path="/esnaf-vergi-muafiyeti" element={<TaxExemption />} />

<Route path="/mesafeli-satis-ve-iptal-iade-sözlesmesi" element={<Distance/>} />
    
          

            {/* Korumalı Panel rotası */}
            <Route
              path="/panelim/*"
              element={
                <ProtectedRoute>
                  <DashboardRouter />
                </ProtectedRoute>
              }
            />

         
          </Route>
        </Routes>
      </Router>
    </AuthContext.Provider>
  );
}



export default App;
