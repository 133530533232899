import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../Footer';

const TermsOfUse = () => {
  return (
    <Container fluid className="px-4 py-3">
      <Row className="mt-5">
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F', marginTop: '40px' }}>Web Site Kullanım & Üyelik Sözleşmesi</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>TARAFLAR</h4>
          <p style={{ color: 'white' }}>
            İşbu Web Site Kullanım, Üyelik Sözleşmesi ("Üyelik Sözleşmesi"), Mehmet Akif Ersoy Mahallesi 93. Cad.Wings Kuleleri, A Blok No: 8 Yenimahalle / Ankara adresinde mukim Sanatkardan E Ticaret İnternet Mağazacılık Sanayi Limited Şirketi ile indirimlikargo.com alan adlı Websitesi'nde üye olan Müşteri arasında, Sanatkardan E Ticaret tarafından Websitesi'nde sunulan hizmetlerin, bu hizmetlerden "Müşteri" tarafından yararlanılmasına ilişkin şartların ve koşulların belirlenmesi ve bu doğrultuda tarafların hak ve yükümlülüklerinin tespitidir. Müşteri, Üyelik Sözleşmesi'nin tamamını okuduğunu, içeriğini bütünü ile anladığını ve tüm hükümlerini onayladığını kabul ve beyan eder.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>TANIMLAR</h4>
          <ul style={{ color: 'white' }}>
            <li><strong >•Gizlilik ve Çerez (Cookie) Politikası:</strong> Kullanıcıların Web sitesini en verimli şekilde kullanabilmelerini ve alışveriş deneyimlerini mümkün olan en yüksek seviyede iyileştirmek amacıyla kullanılan, tamamı ve güncel haline bu adresten ulaşabileceğiniz Politikayı İfade eder.</li>
            <li><strong >•Kişisel Verilerin Koruma ve İşleme Politikası:</strong> Şirketimizin 6698 Sayılı Kişisel Verilerin Korunması Kanunu Kapsamında, Veri sorumlusu sıfatıyla kayıt altında tuttuğu kişisel verileri, bu verilerin hangi amaçla ne şekilde işleneceği ve ne amaçla hangi 3. Kişi ve kurumlara aktarılabileceği gibi içeriklerin yer aldığı bu adresten tamamına erişebileceğiniz Şirket Politikası.</li>
            <li><strong >•Sanatkardan Cüzdan Hesabı:</strong> Web site üzerinde sunduğumuz mal ve hizmetlerin satın alım işlemlerinin gerçekleştirilebileceği ve Sistemimizde kayıtlı sanatkarların (Satıcıların) başarıyla tamamlanan satışlarına istinaden hak ediş bedellerinin aktarıldığı Ön ödemeli Çevrimiçi sanal hesabı ifade eder.</li>
            <li><strong >•Ziyaretçi:</strong> Web sitemize (indirimlikargo.com)’a kayıtlı olmadan ziyaret eden gerçek kişi kullanıcıları ifade eder.</li>
            <li><strong >Hizmet/Hizmetler:</strong> "Müşteri" üyelerin işbu sözleşme içerisinde tanımlı olan iş ve işlemlerini gerçekleştirmelerini sağlamak amacıyla "SANATKARDAN" tarafından ortaya konulan uygulamalar.</li>
            <li><strong >•Kullanıcı / Kullanıcılar:</strong> "Websitesi"ne erişen gerçek ve tüzel kişiler.</li>
            <li><strong >•Websitesi:</strong> indirimlikargo.com alan adı ve alt alan adlarından ulaşılabilen "SANATKARDAN”IN işbu sözleşme ile kapsamı belirlenen "Hizmetlerini sunduğu web Websitesi.</li>
            <li><strong >•Üye/Üyeler:</strong> "Websitesi"ne üye olan ve "Websitesi"nde yer alan içerik, uygulama ve "Hizmetlerden yararlanan gerçek ve tüzel kişiler.</li>
            <li><strong >•Müşteri:</strong> "Websitesi" üzerinde "Satıcı" tarafından satışa arz edilen mal ve/veya hizmetleri satın alan gerçek veya tüzel kişi "Üye".</li>
            <li><strong >•Satıcı (‘’Sanatkar’’):</strong> "Websitesi" üzerinde mal ve/veya hizmet satışa arz eden gerçek veya tüzel kişi "Üye".</li>
            <li><strong >•Sanal Mağaza:</strong> "Satıcıların, kendileri tarafından sağlanan birden fazla mal ve/veya hizmete ilişkin ilanları kendileri için özel olarak ayrılan bir bölümde yayınlayabilme imkanına ve "SANATKARDAN" tarafından belirlenen diğer imkanlara sahip olabilecekleri, detaylı özellikleri "Websitesi"nin ilgili bölümünde belirtilen ve "Websitesi" üzerinden sağlanan "Hizmet".</li>
            <li><strong >•Üye Sayfası:</strong> "Müşteri"nin "Websitesi"de yer alan çeşitli uygulamalardan ve "Hizmetlerden yararlanabilmesi için gerekli işlemleri gerçekleştirebildiği, kişisel bilgilerini ve uygulama bazında kendisinden talep edilen bilgilerini girdiği sadece "Müşteri" tarafından belirlenen kullanıcı adı ve şifre ile erişilebilen "Müşteri" üyeye özel sayfa.</li>
          </ul>
        </Col>
      </Row>

      {/* SÖZLEŞMENİN KONUSU VE KAPSAMI Bölümü */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>SÖZLEŞMENİN KONUSU VE KAPSAMI</h4>
          <p style={{ color: 'white' }}>
            İşbu sözleşmenin konusu, "Websitesi"nde "SANATKARDAN" tarafından sunulan hizmetlerin, bu hizmetlerden "Müşteri" tarafından yararlanılmasına ilişkin şartların ve koşulların belirlenmesi ve bu doğrultuda tarafların hak ve yükümlülüklerinin tespitidir.
          </p>
          <p style={{ color: 'white' }}>
            İşbu sözleşmenin kapsamı ise,"Websitesi"de yer alan tüm "Hizmet", uygulama ve içeriğe ilişkin şart ve koşulların belirlenmesi olup "Websitesi"de "SANATKARDAN" tarafından sağlanan "Hizmetlere, kullanıma, içeriklere, uygulamalara, tüm "Üye"lere ve kullanıcılara yönelik her türlü beyan işbu sözleşmenin ayrılmaz parçası olarak kabul edilecektir. İşbu sözleşmenin "Müşteri" tarafından kabulü ile "Müşteri" "Websitesi"de yer alan ve yer alacak olan "SANATKARDAN" tarafından yapılan "Hizmetlere, kullanıma, içeriklere, uygulamalara, "Üye"lere ve kullanıcılara yönelik her türlü beyanı da kabul etmiş olduğunu kabul, beyan ve taahhüt eder.
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>HAK VE YÜKÜMLÜLÜKLER</h4>
    <p style={{ color: 'white' }}>
      <strong>4.1.</strong> "Müşteri”lik statüsünün kazanılması için, "Müşteri" üye olmak isteyen "Kullanıcının "Websitesi"de bulunan üyelik formunu Sanatkardan E Ticaret Üyelik Sözleşmesi'ni onaylayarak, üyelik formunda talep edilen bilgiler doğrultusunda gerçek bilgilerle doldurması ve üyelik başvurusunun "SANATKARDAN" tarafından değerlendirilerek onaylanması ve üye olmak isteyen "Kullanıcının gerçek kişi ise 18 yaşını doldurmuş olması gerekmektedir. ...
    </p>
    <p style={{ color: 'white' }}>
      <strong>4.2.</strong> "Müşteri" "Websitesi" üzerinden görüntülediği ilanlarla ilgili herhangi bir hukuki işlem veya satın alma süreci başlatmak istemesi halinde "Websitesi"ndekiler de dahil olmak üzere tüm gerekli yasal yükümlülükleri ve yasal prosedürleri yerine getirmek zorunda olduğunu, söz konusu yükümlülükler ve prosedürlerle ilgili olarak "SANATKARDAN”IN herhangi bir bilgi ve sorumluluğunun bulunmadığını kabul ve beyan eder.
    </p>
    <p style={{ color: 'white' }}>
      <strong>4.3.</strong> "Müşteri", "Üye" ve "Kullanıcı" statülerinde iken "Websitesi" üzerinde gerçekleştirdiği işlemlerde ve yazışmalarda; işbu sözleşmenin hükümlerine, "Websitesi"nde belirtilen tüm şart ve koşullara ve yürürlükteki mevzuata, ahlak kurallarına uygun olarak hareket edeceğini, "Websitesi" üzerinden kendisi tarafından gerçekleştirilen tüm işlemlerde reklam veren, satıcı, vergi mükellefi veya benzeri sıfatlarla ilgili mevzuatın gerektirdiği önlem ve usule ilişkin işlemleri yerine getirmek zorunda olduğunu, bu kural ve koşulları anladığını ve kabul ettiğini beyan eder. ...
    </p>
    <p style={{ color: 'white' }}>
      <strong>4.4.</strong> "Müşteri", "Websitesi"ne kendisi tarafından yüklenen bilgi, içerik ve ilanların doğru ve hukuka uygun olduğunu, karalama, kötüleme, itibar zedeleyici beyan, hakaret, iftira atma, tehdit veya taciz etme gibi hukuka veya ahlaka aykırı nitelik taşımayacağını ...
    </p>
    <p style={{ color: 'white' }}>
      <strong>4.5.</strong> "Müşteri”nin "Müşteri Üye Profili Sayfası"na erişmek ve "Websitesi" üzerinden bazı işlemleri gerçekleştirebilmek için ihtiyaç duyduğu kullanıcı adı ve şifre bilgisi, "Müşteri" tarafından oluşturulmakta olup, söz konusu bilgilerin güvenliği ve gizliliği tamamen "Müşteri”nin sorumluluğundadır. ...
    </p>
    <p style={{ color: 'white' }}>
      <strong>4.6.</strong> "SANATKARDAN", yürürlükteki mevzuat uyarınca yetkili makamlardan talep gelmesi halinde "Müşteri”nin kendisinde bulunan bilgilerini ilgili yetkili makamlarla paylaşacaktır. ...
    </p>
    <p style={{ color: 'white' }}>
      <strong>4.7.</strong> "SANATKARDAN", "Websitesi"nin herhangi bir şekilde hukuka aykırı kullanımına ve özellikle aşağıda belirtilen kapsamda "Websitesi"nin her türlü kullanımına ve "Websitesi" üzerindeki içeriğin bütününün veya bir bölümünün her türlü elde edilmesine, kopyalanmasına, işlenmesine, kullanılmasına ve "Websitesi" üzerindeki içeriğe link verilmesine izin vermemekte ve rıza göstermemektedir.
    </p>
    <ul style={{ color: 'white' }}>
      <li>"Websitesi"nin "Müşteri" veya üçüncü bir kişi tarafından kendisine ya da başka bir kişiye ait veri tabanı, kayıt veya rehber yaratmak, kontrol etmek, güncellemek, değiştirmek amacıyla kullanılması;</li>
      <li>"Websitesi"nin bütününün veya herhangi bir bölümünün bozma, değiştirme, ters mühendislik yapma amacıyla kullanılması;</li>
      <li>Yanlış bilgiler kullanılarak veya başka bir kullanıcının bilgileri kullanılarak işlem yapılması, yanlış veya yanıltıcı kişisel bilgiler ...</li>
      <li>Websitesi’nin her türlü yasadışı ya da hile amacı ile kullanılması,</li>
      <li>Kişisel gizlilik ve yayın hakları da dâhil olmak üzere telif hakkı alınmış, hukuken korunan veya "Websitesi"'ye ya da 3. şahsa ait içeriğin tamamını veya bir kısmını sahibi olmadan veya izinsiz kullanılması ...</li>
      {/* Diğer liste öğeleri devam ediyor */}
    </ul>
    <p style={{ color: 'white' }}>
      <strong>4.8.</strong> "SANATKARDAN", "Müşteri" tarafından "Websitesi"ne beyan edilen "Üye/Kişisel" bilgilerini, Gizlilik Politikasına uygun olarak, işbu sözleşme ile belirlenen yükümlülüklerini ve "Websitesi"nin işletilmesi için gereken uygulamaların yürütülmesini ifa ve kendisi tarafından belirlenen istatistiki değerlendirmeler, reklam, pazarlama ve sair ticari iletişim faaliyetleri amacıyla kendisi ya da iş ortakları adına kullanabilir ve saklayabilir ve paylaşılabilir. ...
    </p>
    <p style={{ color: 'white' }}>
      <strong>4.9.</strong> "Müşteri"; kendisi tarafından "Websitesi"ne sağlanan her türlü bilgi, içerik, materyal veya verinin virüs, casus yazılım, kötü niyetli yazılım, Truva atı, vs. gibi "Websitesi"nde veya herhangi bir parçasına zarar verecek nitelikte materyaller içermemesi için gerekli her türlü tedbiri (gerekli anti-virüs yazılımlarını kullanmak da dâhil olmak üzere) aldığını kabul, beyan ve taahhüt eder.
    </p>
  </Col>
</Row>
<Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ</h4>
    <p style={{ color: 'white' }}>
      Şirketimiz kullanıcıların kişisel verilerinin güvenliği ve gizliliği konusuna titizlikle ehemmiyet vermekte olup, üye ve kullanıcılarına ait kişisel verileri, tamamına web sitemizde bu adres üzerinden ulaşabileceğiniz Kişisel Verileri koruma ve İşleme Politikası, Gizlilik ve Çerez politikası kapsamında, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’na uygun olarak işlemektedir. Kişisel verilerin hangi koşullarda nasıl ve ne amaçla işlendiği, Veri sahibinin Kanuni hakları ve haklarını nasıl ne şekilde kullanabileceği yukarıda belirtilen Kişisel Verileri koruma ve İşleme Politika’sında belirtilmektedir.
    </p>
  </Col>
</Row>

<Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>FİKRİ MÜLKİYET HAKLARI</h4>
    <p style={{ color: 'white' }}>
      Müşteri, Websitesi üzerinden sunulan hizmetleri SANATKARDAN E TİCARET bilgilerini ve SANATKARDAN’IN telif haklarına tabi çalışmalarını yeniden satamaz, paylaşamaz, dağıtamaz, sergileyemez, çoğaltamaz, bunlardan türemiş çalışmalar yapamaz veya hazırlayamaz veya başkasının bu hizmetlere erişmesine veya kullanmasına izin veremez; aksi takdirde, lisans verenler de dâhil ancak bunlarla sınırlı olmaksızın, üçüncü kişilerin uğradıkları zararlardan dolayı SANATKARDAN’ DAN dan talep edilen tazminat miktarını ve mahkeme masrafları ve avukatlık ücreti de dâhil ancak bununla sınırlı olmamak üzere diğer her türlü yükümlülükleri karşılamakla sorumlu olacaklardır.
    </p>
    <p style={{ color: 'white' }}>
      SANATKARDAN’IN, SANATKARDAN E TİCARET Hizmet’leri, SANATKARDAN E TİCARET bilgileri, SANATKARDAN E TİCARET TELİF haklarına tabi çalışmalar, Sanatkardan E Ticaret ticari markaları, SANATKARDAN E TİCARET TİCARİ görünümü veya Websitesi vasıtasıyla sahip olduğu her tür maddi ve fikri mülkiyet hakları da dahil tüm malvarlığı, ayni ve şahsi hakları, ticari bilgi ve know-how'a yönelik tüm hakları saklıdır.
    </p>
  </Col>
</Row>

<Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>SÖZLEŞME DEĞİŞİKLİKLERİ</h4>
    <p style={{ color: 'white' }}>
      "SANATKARDAN", tamamen kendi takdirine bağlı ve tek taraflı olarak işbu sözleşmeyi uygun göreceği herhangi bir zamanda "Websitesi"nde ilan ederek değiştirebilir. İşbu sözleşmenin değişen hükümleri, ilan edildikleri tarihte geçerlilik kazanacak, geri kalan hükümler aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. İşbu sözleşme, "Müşteri" üyenin tek taraflı beyanları ile değiştirilemez.
    </p>
  </Col>
</Row>
<Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>MÜCBİR SEBEPLER</h4>
    <p style={{ color: 'white' }}>
      Hukuken mücbir sebep sayılan tüm durumlarda, SANATKARDAN E TİCARET İŞBU Sözleşme ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle sorumlu değildir. Mücbir sebep hallerinde; gecikme, eksik ifa etme veya ifa etmeme veya temerrüt addedilmeyecek veya bu durumlar için Müşteri tarafından SANATKARDAN’DAN herhangi bir nam altında tazminat talep edilemeyecektir. Mücbir sebep terimi; doğal afet, isyan, savaş, grev, siber saldırı, iletişim sorunları, altyapı ve internet arızaları, sisteme ilişkin iyileştirme veya yenileştirme çalışmaları ve bu sebeple meydana gelebilecek arızalar, elektrik kesintisi ve kötü hava koşulları da dâhil ve fakat bunlarla sınırlı olmamak kaydıyla SANATKARDAN’IN makul kontrolü haricinde ve gerekli özeni göstermesine rağmen önlenemeyen kaçınılamaz olaylar olarak yorumlanacaktır.
    </p>
  </Col>
</Row>

<Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>SANATKARDAN E TİCARET KAYITLARININ GEÇERLİLİĞİ</h4>
    <p style={{ color: 'white' }}>
      "Müşteri", işbu sözleşmeden doğabilecek ihtilaflarda "SANATKARDAN’IN kendi veri tabanında, sunucularında tuttuğu elektronik ve sistem kayıtlarının, ticari kayıtlarının, defter kayıtlarının, mikrofilm, mikro fiş ve bilgisayar kayıtlarının muteber bağlayıcı, kesin ve münhasır delil teşkil edeceğini, "SANATKARDAN”I yemin teklifinden ber'i kıldığını ve bu maddenin HMK 193. Madde anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder.
    </p>
  </Col>
</Row>

<Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>UYGULANACAK HUKUK VE YETKİ</h4>
    <p style={{ color: 'white' }}>
      İşbu sözleşmenin uygulanmasında, yorumlanmasında ve işbu sözleşme dâhilinde doğan hukuki ilişkilerin yönetiminde Türk Hukuku uygulanacaktır. İşbu sözleşmeden dolayı doğan veya doğabilecek her türlü ihtilafın hallinde Ankara Mahkemeleri ve İcra Daireleri yetkilidir.
    </p>
  </Col>
</Row>

<Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>BÜTÜNLÜK</h4>
    <p style={{ color: 'white' }}>
      İşbu sözleşmenin herhangi bir hükmünün veya sözleşmede yer alan herhangi bir ifadenin geçersizliği, yasaya aykırılığı ve uygulanamazlığı, sözleşmenin geri kalan hükümlerinin yürürlüğünü ve geçerliliğini etkilemeyecektir.
    </p>
  </Col>
</Row>

<Row className="mt-4">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>BİLDİRİM</h4>
    <p style={{ color: 'white' }}>
      Müşteri’ler ile kaydolurken bildirdikleri e-posta vasıtasıyla veya Web Sitesi’nde yer alan genel bilgilendirme aracılığıyla iletişim kurulacaktır. E-posta ile yapılan iletişim yazılı iletişimin yerini tutar. E-posta adresini güncel tutmak ve Web Sitesi’ni bilgilendirmeler için düzenli kontrol etmek Müşteri’nin sorumluluğundadır.
    </p>
  </Col>
</Row>

<Row className="mt-4">
  <Col>
    <p style={{ color: 'white' }}>
      Müşteri Üyelik tarihi itibariyle, işbu sözleşmeyi elektronik ortamda onaylamakla, sözleşme hükümleriyle üyeliği süresince bağlı kalacağını, kabul, beyan ve taahhüt etmektedir.
    </p>
  </Col>
</Row>
<Footer/>

    </Container>
  );
};

export default TermsOfUse;
