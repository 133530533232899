import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import axios from 'axios';
import { FaFileAlt, FaDownload } from 'react-icons/fa';

const Contracts = () => {
  const [user, setUser] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL; 

  useEffect(() => {
    
    const fetchUserData = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`, {
      headers: { 'x-auth-token': token }
    });
    setUser(response.data);
  } catch (error) {
    console.error('Kullanıcı verileri alınırken bir hata oluştu:', error);
  }
};

    fetchUserData();
  }, []);

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <Image
            src={user.profileImage ? user.profileImage : `https://via.placeholder.com/120?text=${user.firstName?.[0]}${user.lastName?.[0]}`}
            roundedCircle
            className="mb-3"
            style={{ width: '120px', height: '120px' }}
          />
          <h4>{`${user.firstName} ${user.lastName}`}</h4>
          <p>{user.email}</p>
          <p>{user.phone}</p>
        </Col>
      </Row>

      <hr />

      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h5>SÖZLEŞMELER</h5>
          <p>
            Sistemimize Kayıt Aşamasında Onayladığınız Sözleşmelerin Güncel Hallerine Aşağıdan Ulaşabilirsiniz. 
            Sözleşmelerimizde Güncelleme Olmadan Öncesinde Tarafınıza Bilgilendirme Yapılır.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={8}>
          <div className="contract-item d-flex justify-content-between align-items-center p-3 my-2" style={{ border: '1px solid #FFD700', borderRadius: '10px' }}>
            <div className="d-flex align-items-center">
              <FaFileAlt style={{ fontSize: '24px', color: '#FFD700', marginRight: '10px' }} />
              <span>Web Site Üyelik Sözleşmesi</span>
            </div>
            <Button variant="link">
              <FaDownload style={{ fontSize: '24px', color: '#FFD700' }} />
            </Button>
          </div>

          <div className="contract-item d-flex justify-content-between align-items-center p-3 my-2" style={{ border: '1px solid #FFD700', borderRadius: '10px' }}>
            <div className="d-flex align-items-center">
              <FaFileAlt style={{ fontSize: '24px', color: '#FFD700', marginRight: '10px' }} />
              <span>Kişisel Verilerin Korunması Politikamız</span>
            </div>
            <Button variant="link">
              <FaDownload style={{ fontSize: '24px', color: '#FFD700' }} />
            </Button>
          </div>

          <div className="contract-item d-flex justify-content-between align-items-center p-3 my-2" style={{ border: '1px solid #FFD700', borderRadius: '10px' }}>
            <div className="d-flex align-items-center">
              <FaFileAlt style={{ fontSize: '24px', color: '#FFD700', marginRight: '10px' }} />
              <span>Aydınlatma ve Açık Rıza Metni</span>
            </div>
            <Button variant="link">
              <FaDownload style={{ fontSize: '24px', color: '#FFD700' }} />
            </Button>
          </div>

          <div className="contract-item d-flex justify-content-between align-items-center p-3 my-2" style={{ border: '1px solid #FFD700', borderRadius: '10px' }}>
            <div className="d-flex align-items-center">
              <FaFileAlt style={{ fontSize: '24px', color: '#FFD700', marginRight: '10px' }} />
              <span>Gizlilik ve Çerez Politikamız</span>
            </div>
            <Button variant="link">
              <FaDownload style={{ fontSize: '24px', color: '#FFD700' }} />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contracts;
