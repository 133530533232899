import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';

const Profile = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    cityName: '',
    districtName: '',
    postalCode: '', // Posta kodu alanı eklendi
    companyName: '',
    companyAddress: '',
    companyCityName: '',
    companyDistrictName: ''
  });

  const [error, setError] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = useSelector((state) => state.user.userId); // Redux'tan userId'yi alıyoruz

  // Kullanıcı bilgilerini almak için tek bir API çağrısı
  const fetchUserData = async () => {
    try {
      const userResponse = await axios.get(`${apiUrl}/user/${userId}`, {
        withCredentials: true,
      });

      setUser(userResponse.data.user);  // Kullanıcı ve adres bilgilerini aynı modelden alıyoruz
    } catch (error) {
      setError('Kullanıcı bilgileri alınırken hata oluştu.');
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();  // Kullanıcı ve adres bilgilerini çek
    }
  }, [userId]);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <h2 className="text-center mb-4">Kullanıcı Profili</h2>

          <Card className="mb-4 shadow-sm p-3" style={{ borderRadius: '15px' }}>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <h5>Genel Bilgiler</h5>
                  <hr />
                  <p><strong>Ad:</strong> {user.firstName || 'Ad eklenmedi'}</p>
                  <p><strong>Soyad:</strong> {user.lastName || 'Soyad eklenmedi'}</p>
                  <p><strong>Email:</strong> {user.email || 'Email eklenmedi'}</p>
                  <p><strong>Telefon:</strong> {user.phone || 'Telefon eklenmedi'}</p>
                </Col>
                <Col md={6}>
                  <h5>Adres Bilgileri</h5>
                  <hr />
                  <p><strong>Adres:</strong> {user.address || 'Adres eklenmedi'}</p>
                  <p><strong>Şehir:</strong> {user.cityName || 'Şehir eklenmedi'}</p>
                  <p><strong>İlçe:</strong> {user.districtName || 'İlçe eklenmedi'}</p>
                  <p><strong>Posta Kodu:</strong> {user.postalCode || 'Posta kodu eklenmedi'}</p> {/* Posta kodu eklendi */}
                  {/* <Button variant="primary" className="mt-3 w-100">Adres Güncelle</Button> */}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="shadow-sm p-3" style={{ borderRadius: '15px' }}>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <h5>Şirket Bilgileri</h5>
                  <hr />
                  <p><strong>Şirket Adı:</strong> {user.companyName || 'Şirket adı eklenmedi'}</p>
                  <p><strong>Şirket Adresi:</strong> {user.companyAddress || 'Şirket adresi eklenmedi'}</p>
                </Col>
                <Col md={6}>
                  <h5>Şirket Lokasyonu</h5>
                  <hr />
                  <p><strong>Şirket Şehri:</strong> {user.companyCityName || 'Şirket şehri eklenmedi'}</p>
                  <p><strong>Şirket İlçesi:</strong> {user.companyDistrictName || 'Şirket ilçesi eklenmedi'}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
