import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
const apiUrl = process.env.REACT_APP_API_URL;

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post(`${apiUrl}/api/auth/reset-password/${token}`, { password });

      setMessage(response.data.msg);
    } catch (err) {
      setMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  return (
    <div>
      <h2>Yeni Şifre Oluştur</h2>
      <form onSubmit={handleResetPassword}>
        <input
          type="password"
          placeholder="Yeni şifrenizi girin"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Şifremi Sıfırla</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPassword;
