import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, OverlayTrigger, Tooltip, Offcanvas } from 'react-bootstrap';
import { BsWallet, BsInfoCircle } from 'react-icons/bs';
import axios from 'axios';
import BalanceLoad from './BalanceLoad'; // BalanceLoad bileşenini import edin
import { useSelector } from 'react-redux';
import { IoWalletOutline } from "react-icons/io5";

const WalletCard = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [balance, setBalance] = useState(0);
  const [blockedBalance, setBlockedBalance] = useState(0);
  const [usableBalance, setUsableBalance] = useState(0);
  const [averageShippingCost, setAverageShippingCost] = useState(0);
  const [transactions, setTransactions] = useState([]);
  
  // Yeni eklenen state'ler
  const [transactionType, setTransactionType] = useState('');
  const [expenseType, setExpenseType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Redux'tan userId'yi alıyoruz
  const userId = useSelector((state) => state.user.userId);
  const apiUrl = process.env.REACT_APP_API_URL; // Dinamik API URL

  // Cüzdan verilerini alma
  const fetchWalletData = async () => {
    try {
      const balanceResponse = await axios.get(`${apiUrl}/wallet/balance`, {
        withCredentials: true,
      });

      // Yanıtı konsola yazdır
      console.log("Balance Response:", balanceResponse.data);

      // State'leri güncelle
      setBalance(balanceResponse.data.balance || 0);
      setBlockedBalance(balanceResponse.data.blockedBalance || 0);
      setUsableBalance(balanceResponse.data.usableBalance || 0);
      setAverageShippingCost(balanceResponse.data.averageShippingCost || 0);
      
      // Eğer işlem geçmişini ayrı bir endpoint ile almak istiyorsanız burayı ekleyin
      const transactionsResponse = await axios.get(`${apiUrl}/wallet/transactions`, {
        withCredentials: true,
      });
      
      // İşlem geçmişi yanıtını konsola yazdır
      console.log("Transactions Response:", transactionsResponse.data);
      setTransactions(transactionsResponse.data.transactions || []);
      
    } catch (error) {
      console.error('Wallet data fetching error:', error);
    }
  };

  // İlk yükleme ve userId değişikliği için veri çekme
  useEffect(() => {
    fetchWalletData();
  }, [userId]);

  // Tooltip içeriği
  const tooltip = (
    <Tooltip id="tooltip-basic" style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', padding: '10px' }}>
      <p style={{ margin: '0', color: 'red' }}><strong>GÖNDERİ ORTALAMASI:</strong></p>
      <p style={{ margin: '0' }}>Son gönderdiğiniz 10 adet kargo taşıma bedelinin ortalaması</p>
      
      <p style={{ margin: '0', color: 'red' }}><strong>BLOKLANAN BAKİYE:</strong></p>
      <p style={{ margin: '0' }}>Henüz teslim etmediğiniz kargoların bloklanmış tutarı</p>

      <p style={{ margin: '0', color: 'red' }}><strong>KULLANILABİLİR BAKİYE:</strong></p>
      <p style={{ margin: '0' }}>Bloklanan tutardan sonra kullanılabilir bakiye</p>

      <p style={{ margin: '0', color: 'red' }}><strong>BAKİYE:</strong></p>
      <p style={{ margin: '0' }}>Kullanılabilir ve bloklanan toplam bakiye</p>
    </Tooltip>
  );

  return (
    <>
      <Row className="align-items-stretch mb-4">
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill p-3">
            <Row>
              <Col xs={3} className="d-flex align-items-center justify-content-center">
                <IoWalletOutline size={50} />
              </Col>
              <Col xs={9}>
                <h3>{balance.toFixed(2)}₺</h3>
                <Button
                  variant="link"
                  className="p-0"
                  style={{ textDecoration: 'none', color: '#EFB02F' }} // Yazı rengi sarı
                  onClick={() => setShowOffcanvas(true)}
                >
                  BAKİYE YÜKLE / ÇEK
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill p-3">
            <p className="mb-1">
              Gönderi ortalaması: <strong>{averageShippingCost.toFixed(2)}₺</strong> Bakiye: <strong>{balance.toFixed(2)}₺</strong>
            </p>
            <p className="mb-1">
              Bloklanan Bakiye: <strong>{blockedBalance.toFixed(2)}₺</strong>&nbsp;
              Kullanılabilir Bakiye: <strong>{usableBalance.toFixed(2)}₺</strong>
            </p>

            <Button
              variant="link"
              className="p-0"
              style={{ textDecoration: 'none', color: '#EFB02F' }} // Yazı rengi sarı
              onClick={() => console.log('Tüm hareketler')} // Burayı kontrol edin; loadMoreTransactions fonksiyonu tanımlanmalı
            >
              TÜM HAREKETLER
            </Button>

            <OverlayTrigger 
              placement="left" 
              overlay={tooltip}
            >
              <Button variant="link" className="p-0" style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <BsInfoCircle size={20} />
              </Button>
            </OverlayTrigger>
          </Card>
        </Col>
      </Row>
      {/* Offcanvas for Bakiye Yükle / Çek */}
      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end" backdrop={true} scroll={true}>
        <Offcanvas.Header closeButton className='text-warning'>
          <Offcanvas.Title className="w-100 text-center">BAKİYE YÜKLE / ÇEK</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <BalanceLoad 
            usableBalance={usableBalance} // Kullanılabilir bakiyeyi hesapla
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default WalletCard;
